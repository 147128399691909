body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* custom tags and flags for editorPage rich text sections */
.break-tag {
  background: rgb(229, 229, 173);
  border-radius: 2px;
  border: 0;
  box-sizing: content-box;
  color: rgb(128, 126, 45);
  display: inline;
  height: auto;
  margin: 0;
  padding: 0px;
  text-decoration-color: rgba(181, 180, 58, 1);
  text-decoration-line: underline;
  width: auto;
}

.flag-word {
  background: rgba(190, 231, 255, 1);
  border-radius: 2px;
  border: 0;
  box-sizing: content-box;
  color: black;
  display: inline;
  height: auto;
  margin: 0;
  padding: 0px;
  text-decoration-color: blue;
  text-decoration-line: underline;
  width: auto;
}

.bad-syntax {
  background: rgba(239, 154, 154, 0.5);
  border-radius: 2px;
  border: 0;
  box-sizing: content-box;
  color: red;
  display: inline;
  height: auto;
  margin: 0;
  padding: 0px;
  text-decoration-color: rgb(173, 25, 25);
  text-decoration-line: underline;
  width: auto;
}

.flag-lexicon {
  background: rgba(229, 255, 237, 1);
  border-radius: 2px;
  border: 0;
  box-sizing: content-box;
  color: black;
  display: inline;
  height: auto;
  margin: 0;
  padding: 0px;
  text-decoration-color: green;
  text-decoration-line: underline;
  width: auto;
}

.flag-img-lexicon {
  height: 14px;
  width: 16px;
  object-fit: cover;
  border-radius: 16px;
  background-color: black;
  outline: 1px solid rgba(137, 138, 138, 0.2);
  margin-right: 5px;
}

.flag-liaison {
  background: rgba(253, 237, 223, 1);
  border-radius: 2px;
  border: 0;
  box-sizing: content-box;
  color: black;
  display: inline;
  height: auto;
  margin: 0;
  padding: 0px;
  text-decoration-color: orange;
  text-decoration-line: underline;
  width: auto;
}

.swal2-container {
  position: fixed;
  z-index: 300000;
}